<template>
  <v-app-bar class="tw-px-4" flat app>
    <v-app-bar-nav-icon class="xl:tw-hidden" @click="doToggleDrawer()"></v-app-bar-nav-icon>
    <div class="tw-flex tw-w-full tw-justify-between">
      <v-toolbar-title>
        <TextNormal class="tw-uppercase tw-text-2xl tw-font-bold">{{toLowerCase($route.name)}}</TextNormal>
      </v-toolbar-title>
    </div>
  </v-app-bar>
</template>

<script>

// Utilities
import { mapState } from 'vuex'
import { TOGGLE_DRAWER } from '@/store/modules/app'
import {
  lowerCase,
} from 'lodash'


// Components 
import TextNormal from '@/components/atoms/TextNormal'

export default {
  name: 'DashboardCoreAppBar',
  components : {
    TextNormal,
  },

  computed: {
    ...mapState({
      // state here
    }),
  },

  methods: {
    toLowerCase (name) {
      return lowerCase(name);
    },
    // ...mapMutations({
    //   setDrawer: 'SET_DRAWER',
    // }),
    doToggleDrawer () {
      this.$store.commit(TOGGLE_DRAWER)
    },
  },
}
</script>

<style>

</style>